import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const NPMDownloadsComponent = () => {
  const [author, setAuthor] = useState("");
  const [packages, setPackages] = useState("");
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDownloads = async () => {
    setLoading(true);
    setError(null);

    let packageNames = [];

    if (author) {
      const response = await fetch(
        `https://registry.npmjs.org/-/v1/search?text=maintainer:${author}&size=250`
      );
      const data = await response.json();
      packageNames = data.objects.map((obj) => obj.package.name);
    } else {
      packageNames = packages.split(",").map((pkg) => pkg.trim());
    }

    const downloadPromises = packageNames.map(async (pkg) => {
      let currentStartDate = startDate;
      let currentEndDate = endDate;
      let totalDownloads = 0;

      while (currentStartDate.isBefore(endDate)) {
        const start = currentStartDate.format("YYYY-MM-DD");
        currentEndDate = currentStartDate.add(365, "day");
        if (currentEndDate.isAfter(endDate)) {
          currentEndDate = endDate;
        }
        const end = currentEndDate.format("YYYY-MM-DD");

        const response = await fetch(
          `https://api.npmjs.org/downloads/range/${start}:${end}/${pkg}`
        );
        const data = await response.json();

        if (data.error) {
          throw new Error(`Package ${pkg} not found`);
        }

        if (data.downloads) {
          totalDownloads += data.downloads.reduce(
            (total, daily) => total + daily.downloads,
            0
          );
        }

        currentStartDate = currentEndDate.add(1, "day");
      }

      return {
        package: pkg,
        downloads: totalDownloads,
      };
    });

    try {
      const downloadData = await Promise.all(downloadPromises);
      setDownloads(downloadData);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const totalDownloads = downloads.reduce(
    (total, item) => total + item.downloads,
    0
  );

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        NPM Downloads
      </Typography>
      <Box mb={4}>
        <TextField
          label="Author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Typography variant="subtitle1" gutterBottom>
          OR
        </Typography>
        <TextField
          label="Packages (comma-separated)"
          value={packages}
          onChange={(e) => setPackages(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>
      <Box mb={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            renderInput={(params) => <TextField {...params} />}
            format="DD-MM-YYYY"
          />
          <Box mt={2}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} />}
              format="DD-MM-YYYY"
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Button variant="contained" onClick={fetchDownloads} disabled={loading}>
        {loading ? "Fetching..." : "Fetch Downloads"}
      </Button>
      {loading ? (
        <Box mt={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Package</TableCell>
                <TableCell>Downloads</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {downloads.map((item) => (
                <TableRow key={item.package}>
                  <TableCell>{item.package}</TableCell>
                  <TableCell>{formatNumber(item.downloads)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <strong>Total</strong>
                </TableCell>
                <TableCell>
                  <strong>{formatNumber(totalDownloads)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default NPMDownloadsComponent;
